/* KEYFRAMES */

@keyframes jumping-animation {
  0% {   
    transform: translateY(0);
  }
  25% {
    transform: translateY(-5%);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(5%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes pulsing-animation {
  0% {   
    transform: scale(1);
  }
  25% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes heartbeat-animation {
  10% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  55% {
    transform: scale(0.8);
    box-shadow: 0 0 0 0 rgba(240, 90, 91, 1);
  }
  75% {
    transform: scale(1);
  }
  100% {
    box-shadow: 0 0 0 6px rgba(240, 90, 91, 0);
  }
}

@keyframes glow-animation {
  0% {
    box-shadow: 0 0 0 0 rgba(240, 90, 91, 1);
  }
  90% {
    box-shadow: 0 0 0 10px rgba(240, 90, 91, 0);
  }
}

@keyframes jumping-to-right-animation {
  0% {   
    transform: translateX(0);
  }
  50% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(0);
  }
}


/* Animation for clips */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


@keyframes running-gradient {
  0% {
    background-position: 200% 0%;
  }
  100% {
    background-position: 0% 0%
  }
}

@keyframes spinning {
  0% {
    --rotate: 0deg;
  }
  100% {
    --rotate: 360deg;
  }
}