/* GENERAL */

:root {
  --header-height: 70px;
  --page-width: calc(100vw - var(--size-nav-width) - var(--page-spacing));
  --size-nav-width: calc((var(--page-spacing) * 2) + 3.5rem);
  --page-spacing: 3.5vw;
  --content-vertical-spacing: 24px;

  --upload-el-width: calc((var(--page-width) * 0.55 - (1.5rem * 2)) / 3);
  /* 3 blocks with 1.5rem gap – on main part of the page (55%)*/

  --thumbnail-width: calc((var(--page-width) - (1rem * 7)) / 8);
  /* 8 blocks with 1rem gap */

  --event-block-width: calc((var(--page-width) - (1.75rem * 4)) / 5);
  /* 5 blocks with 1.75rem gap */

  --mode-icon-side-a: 22vw;
  --mode-icon-side-b: calc(var(--mode-icon-side-a) * 9 / 16);

  --form-el-height: calc(3.5rem + 2px);
  --border-radius: 0.375rem;
  --box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

  /* colours */
  --primary-color: #dc4e51;
  --primary-color-light: #e88585;
  --primary-color-translucent-2: rgba(220, 78, 81, 0.2);

  --grey: #bbbbbb;
  --light-grey-1: #f4f4f6;
  --light-grey-2: #e1e4e7;
  --light-pink: #fdf1f4;
  --success-color: #198754;
  --error-color: #dc3545;
  --blue-green-color: #21b6a8;

  --gradient-secondary-color: #e0674b;
  --purple-color: #4713f4;
  --purple-color-light: #7f5af9;

  --gradient-to-bottom: linear-gradient(
    to bottom,
    var(--primary-color),
    var(--gradient-secondary-color)
  );
  --gradient-to-right: linear-gradient(
    to right,
    var(--primary-color),
    var(--gradient-secondary-color)
  );
  --gradient-purple-color: linear-gradient(
    to right,
    var(--purple-color),
    var(--primary-color)
  );
  --gradient-purple-light: linear-gradient(
    to right,
    var(--purple-color-light),
    var(--primary-color-light)
  );

  --gradient-primary-loop: linear-gradient(
    to right,
    var(--primary-color),
    var(--gradient-secondary-color),
    var(--primary-color)
  );

  --gradient-purple-loop: linear-gradient(
    to right,
    var(--purple-color-light),
    var(--primary-color-light),
    var(--purple-color-light)
  );

  --dark-overlay: rgba(0, 0, 0, 0.2);
  --very-dark-overlay: rgba(0, 0, 0, 0.6);
}

html {
  scroll-padding-top: calc(var(--header-height) + 10px);
}

.react-thumbnail-generator {
  display: none !important;
}

.ant-upload-drag {
  border-color: #000 !important;
}

button[class^='videoask'].videoask-embed__button_bottom--BJIcx.videoask-embed__button_right--V-il1 {
  bottom: calc(2.5rem + 48px) !important;
  right: var(--page-spacing) !important;
}

/* Links */

a {
  color: var(--primary-color);
}

a:hover {
  color: var(--primary-color);
  opacity: 0.6;
}

/* ANIMATED BOXES */

.animated-box-primary {
  background: var(--gradient-primary-loop);
  background-size: 200% 100%;
}

.animated-box-purple {
  background: var(--gradient-purple-loop);
  background-size: 200% 100%;
}

@property --rotate {
  syntax: '<angle>';
  initial-value: 132deg;
  inherits: false;
}

.running-glow::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 0.375rem;
  background-image: linear-gradient(
    var(--rotate),
    #e0674b,
    #dc4e51 43%,
    #7f5af9
  );
  z-index: -1;
  animation: spinning 2s linear infinite;
}

.running-glow::after {
  position: absolute;
  content: '';
  top: calc(2.5rem / 12);
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  transform: scale(0.9);
  filter: blur(calc(2.5rem / 6));
  background-image: linear-gradient(
    var(--rotate),
    #e0674b,
    #dc4e51 43%,
    #7f5af9
  );
  opacity: 1;
  transition: opacity 0.5s;
  z-index: -1;
  animation: spinning 2s linear infinite;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 1023px) {
  :root {
    --page-width: 90vw;
    --event-block-width: calc((var(--page-width) - (1rem * 3)) / 4);
  }
}

@media only screen and (max-width: 639px) {
  :root {
    --page-spacing: 2vw;
    --upload-el-width: calc((var(--page-width) - 1.5rem) / 2);
    --thumbnail-width: calc((var(--page-width) - (1rem * 2)) / 3);
    --event-block-width: calc((var(--page-width) - 1rem) / 2);
    --mode-icon-side-a: 40vw;
  }

  button[class^='videoask'].videoask-embed__button_bottom--BJIcx.videoask-embed__button_right--V-il1 {
    bottom: 24px !important;
    right: 5vw !important;
  }
}
